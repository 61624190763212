const WebRtcHandler = require('./webrtchandler');


const divSelectRoom = document.getElementById('selectRoom');
const divConsultingRoom = document.getElementById('consultingRoom');
const inputRoomNumber = document.getElementById('roomNumber');
const btnGoRoom = document.getElementById('goRoom');
const localVideo = document.getElementById('localVideo');
const remoteVideo = document.getElementById('remoteVideo');

const iceServers = [
/*
    {
        urls: "turns:coturn.codeventure.co:443",
        credential: 'AiFael1i',
        username: 'cdvntr'

    },
    {
        urls: "turns:coturn2.codeventure.co:443",
        credential: 'AiFael1i',
        username: 'cdvntr'

    },
*/
    {
        urls: "turn:coturn1.codeventure.co:3478",
        //urls: "turn:coturn2.codeventure.co:3478",
        //urls: "turns:coturn2.codeventure.co:443",
        credential: 'AiFael1i',
        username: 'cdvntr'
    },
];


const consoleLog = console.log.bind(console);
console.log = (...args)=>{
    consoleLog(new Date().getTime(), ...args);
};
// Here we add a click event to the button
btnGoRoom.onclick = async () => {
    const room = inputRoomNumber.value;
    if (room === '') {
        alert("Please type a room number")
    } else {
        const localStream = await navigator.mediaDevices.getUserMedia({audio: true, video: true});
        const socket = io();

        function onTrack(event) {
            console.log('onTrack received remote stream');
            if (!remoteVideo.srcObject) {
                remoteVideo.srcObject = new MediaStream();
            }
            remoteVideo.srcObject.addTrack(event.track);
        }

        function sendIceCandidate(candidate) {
            console.log("sending candidate");
            socket.emit('candidate', {room, candidate})
        }

        function sendOffer(offer) {
            console.log("sending offer");
            socket.emit('offer', {room, offer})
        }

        function sendAnswer(answer) {
            console.log("sending answer");
            socket.emit('answer', {room, answer})
        }

        socket.on('offer', async (offer) => {
            console.log("got offer");
            await webRtcHandler.onSignalOffer(offer);
        });

        socket.on('answer', async (answer) => {
            console.log("got answer");
            await webRtcHandler.onSignalAnswer(answer);
        });

        socket.on('candidate', async (candidate) => {
            console.log("got candidate");
            await webRtcHandler.onSignalCandidate(candidate);
        });


        const webRtcHandler = new WebRtcHandler({
            onTrack,
            sendIceCandidate,
            sendOffer,
            sendAnswer,
            iceServers,
            PlatformRTCPeerConnection: RTCPeerConnection
        });

        socket.on('created', async (roomCreated) => {
            if (room !== roomCreated) {
                throw new Error(`room!! ${room} !== ${roomCreated}`)
            }
            console.log(`created room: ${roomCreated}`);

            socket.on('joinerReady', webRtcHandler.makeOffer);
        });

        socket.on('joined', async (roomJoined) => {
            if (room !== roomJoined) {
                throw new Error(`room!! ${room} !== ${roomJoined}`)
            }
            console.log(`joined room: ${roomJoined}`);

            // The recipient receives the offer and calls RTCPeerConnection.setRemoteDescription() to record it as the remote
            socket.emit('joinerReady', room);
        });


        localVideo.srcObject = localStream;

        webRtcHandler.addMediaStreamToRemoteConnection(localStream);


        socket.emit('create or join', room);//we send a message to server

        divSelectRoom.style = 'display: none';//hide selectRoom div
        divConsultingRoom.style = 'display: block';
    }
};








